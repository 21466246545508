import React from "react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-800 dark:bg-gray-900 text-white text-center py-6">
      <div className="container mx-auto">
        {/* Texte principal */}
        <p className="text-sm text-gray-300 dark:text-gray-400">
          &copy; 2024. Tous droits réservés.
        </p>

        {/* Liens vers les réseaux sociaux */}
        <div className="flex justify-center gap-6 mt-4">
          <a
            href="https://github.com/DARTFRA"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-300 hover:text-blue-400 transition-colors text-xl"
          >
            <FaGithub />
          </a>
          <a
            href="https://www.linkedin.com/in/yoann-duzelier-1b1728256/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-300 hover:text-blue-400 transition-colors text-xl"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://x.com/just_yoann_off"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-300 hover:text-blue-400 transition-colors text-xl"
          >
            <FaTwitter />
          </a>
        </div>

        {/* Crédit ou slogan */}
        <p className="text-sm text-gray-400 dark:text-gray-500 mt-4">
          Conçu avec passion par Yoann. 🚀
        </p>
      </div>
    </footer>
  );
};

export default Footer;
