import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaMoon, FaSun, FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  // Détecte si l'utilisateur est sur une route admin
  const isAdminRoute = location.pathname.startsWith("/admin");

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header
      className={`${
        isAdminRoute ? "bg-red-600" : "bg-gray-800"
      } text-white py-4 shadow-lg dark:bg-gray-900`}
    >
      <div className="container mx-auto flex justify-between items-center px-6">
        {/* Logo */}
        <div className="flex items-center">
          <Link to={isAdminRoute ? "/admin/dashboard" : "/"}>
            <img
              src={isAdminRoute ? "imgs/admin_logo.png" : "imgs/logo_white.png"}
              alt={isAdminRoute ? "Admin Logo" : "Main Logo"}
              className="h-10 w-auto transition-transform transform hover:scale-105"
            />
          </Link>
        </div>

        {/* Menu Navigation */}
        <nav
          className={`${
            menuOpen ? "block" : "hidden"
          } md:flex items-center gap-6`}
        >
          {isAdminRoute ? (
            <>
              <Link to="/admin/dashboard" className="hover:underline">
                Dashboard
              </Link>
              <Link to="/admin/users" className="hover:underline">
                Users
              </Link>
              <Link to="/admin/settings" className="hover:underline">
                Settings
              </Link>
            </>
          ) : (
            <>
              <Link to="/" className="hover:underline">
                Home
              </Link>
              <Link to="/projects" className="hover:underline">
                Projects
              </Link>
              <Link to="/about" className="hover:underline">
                About
              </Link>
              <Link to="/contact" className="hover:underline">
                Contact
              </Link>
            </>
          )}
        </nav>

        {/* Boutons (Dark Mode + Menu Mobile) */}
        <div className="flex items-center gap-4">
          <button
            onClick={toggleDarkMode}
            className="text-2xl focus:outline-none transition-transform transform hover:scale-110"
          >
            {darkMode ? (
              <FaSun className="text-yellow-400" />
            ) : (
              <FaMoon className="text-blue-400" />
            )}
          </button>
          <button
            onClick={toggleMenu}
            className="text-2xl md:hidden focus:outline-none"
          >
            {menuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
