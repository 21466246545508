import React from "react";
import Projects from "../components/Projects";

const ProjectsPage = () => {
  return (
    <div className="bg-gray-50 dark:bg-gray-900 dark:text-gray-200 min-h-screen">
      {/* En-tête de la page */}
      <header className="relative bg-gradient-to-r from-indigo-500 via-purple-600 to-blue-500 text-white py-16 text-center shadow-md dark:bg-gradient-to-r dark:from-gray-800 dark:via-gray-900 dark:to-gray-800">
        <h1 className="text-5xl font-extrabold mb-6 animate-fade-in">
          Mes Projets
        </h1>
        <p className="text-lg max-w-3xl mx-auto animate-slide-up">
          Découvrez une sélection de mes projets, allant de la création de sites
          web modernes aux bots Discord entièrement personnalisés.
        </p>

        {/* Décorations visuelles */}
        <div className="absolute top-0 left-10 w-32 h-32 bg-yellow-300 rounded-full blur-3xl opacity-30 animate-pulse dark:bg-yellow-500"></div>
        <div className="absolute bottom-0 right-10 w-48 h-48 bg-blue-400 rounded-full blur-3xl opacity-30 animate-pulse dark:bg-blue-600"></div>
      </header>

      {/* Contenu des projets */}
      <main className="container mx-auto py-12 px-6">
        <section className="mb-12">
          <h2 className="text-4xl font-bold mb-8 text-center border-b-4 border-indigo-500 inline-block dark:border-indigo-400">
            Projets récents
          </h2>

          {/* Grille des projets */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <Projects />
          </div>
        </section>
      </main>
    </div>
  );
};

export default ProjectsPage;
