import React, { useState } from "react";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [status, setStatus] = useState(""); // Pour afficher des messages de succès ou d'erreur

  const webhookUrl =
    "https://discord.com/api/webhooks/1309962599244496936/0Q_d21zmJ1ljjDHawQ_-m1EGPgu2FVK9Jroxrm270dzGHB_-KJ3aDYMnnw2OnGlQCs53";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.message) {
      setStatus("Veuillez remplir tous les champs.");
      return;
    }

    try {
      const payload = {
        content: `**Nouveau message depuis le formulaire de contact :**\n\n**Nom** : ${formData.name}\n**Email** : ${formData.email}\n**Message** : ${formData.message}`,
      };

      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setStatus("Message envoyé avec succès !");
        setFormData({ name: "", email: "", message: "" }); // Réinitialise le formulaire
      } else {
        setStatus("Erreur lors de l'envoi du message. Réessayez plus tard.");
      }
    } catch (error) {
      console.error("Erreur :", error);
      setStatus("Erreur lors de l'envoi du message. Réessayez.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      {/* En-tête */}
      <header className="text-center mb-12">
        <h1 className="text-4xl font-extrabold text-gray-800 dark:text-white mb-4">
          Contactez-moi
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300">
          Si vous avez des questions ou souhaitez en savoir plus sur mes projets,
          n'hésitez pas à m'envoyer un message.
        </p>
      </header>

      {/* Formulaire */}
      <form
        onSubmit={handleSubmit}
        className="max-w-lg mx-auto bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg space-y-6"
      >
        {/* Nom */}
        <div>
          <label
            htmlFor="name"
            className="block text-gray-800 dark:text-white font-medium mb-2"
          >
            Nom
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
            required
          />
        </div>

        {/* Email */}
        <div>
          <label
            htmlFor="email"
            className="block text-gray-800 dark:text-white font-medium mb-2"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
            required
          />
        </div>

        {/* Message */}
        <div>
          <label
            htmlFor="message"
            className="block text-gray-800 dark:text-white font-medium mb-2"
          >
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
            required
          ></textarea>
        </div>

        {/* Message de statut */}
        {status && (
          <div
            className={`text-center p-2 rounded-lg ${
              status.includes("succès") ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
            }`}
          >
            {status}
          </div>
        )}

        {/* Bouton d'envoi */}
        <button
          type="submit"
          className="w-full bg-indigo-500 text-white py-3 rounded-lg shadow-md hover:bg-indigo-600 transition-colors duration-300"
        >
          Envoyer le message
        </button>
      </form>
    </div>
  );
};

export default ContactPage;
