import React from "react";
import Hero from "../components/Hero";

const Home = () => {
  return (
    <div className="bg-gray-50 dark:bg-gray-900 dark:text-gray-200 min-h-screen">
      {/* Section Hero */}
      <Hero />
    </div>
  );
};

export default Home;
