import React from "react";

const AboutPage = () => {
  return (
    <div className="bg-gray-50 dark:bg-gray-900 dark:text-gray-200 min-h-screen">
      {/* En-tête de la page */}
      <header className="relative bg-gradient-to-r from-indigo-500 via-purple-600 to-blue-500 text-white py-16 text-center shadow-md dark:bg-gradient-to-r dark:from-gray-800 dark:via-gray-900 dark:to-gray-800">
        <h1 className="text-5xl font-extrabold mb-6 animate-fade-in">
          À propos de moi
        </h1>
        <p className="text-lg max-w-3xl mx-auto animate-slide-up">
          Découvrez qui je suis, ce que je fais, et comment je peux vous aider à
          réaliser vos idées grâce à mes compétences en développement web et en
          création de bots Discord.
        </p>
      </header>

      {/* Contenu principal */}
      <main className="container mx-auto py-12 px-6">
        {/* Section présentation */}
        <section className="mb-12">
          <h2 className="text-4xl font-bold mb-4">Qui suis-je ?</h2>
          <p className="text-lg text-gray-700 dark:text-gray-300 leading-relaxed">
            Je suis Yoann, un développeur web passionné avec une expertise dans
            la création de bots Discord sur mesure et de sites web modernes. Mon
            objectif est de transformer des idées en solutions digitales
            efficaces et adaptées à vos besoins. Avec plusieurs années
            d'expérience, je mets un point d'honneur à offrir un travail
            professionnel, esthétique, et fonctionnel.
          </p>
        </section>

        {/* Section compétences */}
        <section className="mb-12">
          <h2 className="text-4xl font-bold mb-4">Compétences</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow">
              <h3 className="text-xl font-semibold mb-2">Développement web</h3>
              <p className="text-gray-700 dark:text-gray-300">
                Création de sites web responsives et modernes avec des
                technologies comme React, Tailwind CSS, et Node.js.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow">
              <h3 className="text-xl font-semibold mb-2">Bots Discord</h3>
              <p className="text-gray-700 dark:text-gray-300">
                Conception et développement de bots Discord personnalisés pour
                automatiser vos tâches et enrichir votre communauté.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow">
              <h3 className="text-xl font-semibold mb-2">Gestion de projets</h3>
              <p className="text-gray-700 dark:text-gray-300">
                Coordination de projets techniques pour assurer une livraison
                dans les délais et respecter vos attentes.
              </p>
            </div>
          </div>
        </section>

        {/* Section appel à l'action */}
        <section className="py-16 bg-gradient-to-r from-purple-100 via-blue-100 to-indigo-100 dark:from-gray-800 dark:via-gray-900 dark:to-gray-800">
            <div className="container mx-auto text-center">
            <h3 className="text-4xl font-bold mb-6 text-gray-800 dark:text-white">
              Vous avez un projet ou une idée en tête ?
            </h3>
            <p className="text-lg max-w-2xl mx-auto mb-8 text-gray-700 dark:text-gray-300">
              N'hésitez pas à me contacter pour discuter de vos besoins. Je suis
              toujours à la recherche de nouveaux défis et collaborations.
            </p>
            <a
              href="/contact"
              className="bg-indigo-500 text-white py-3 px-8 rounded-lg shadow-md hover:bg-indigo-600 hover:shadow-lg transition-all transform hover:scale-105 dark:bg-indigo-600 dark:hover:bg-indigo-700"
            >
              Contactez-moi
            </a>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AboutPage;
