import React from "react";

const projects = [
  {
    title: "Collège Sacré Coeur",
    description: "Bot Discord pour la gestion d'un serveur scolaire.",
    link: "https://discord.gg/CKTW5x5wBA",
    image: "https://via.placeholder.com/300x200", // Remplacez par l'image réelle
  },
  {
    title: "Portfolio React",
    description: "Un portfolio interactif et professionnel développé avec React.",
    link: "#",
    image: "https://via.placeholder.com/300x200", // Remplacez par l'image réelle
  },
];

const Projects = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-8">
      {projects.map((project, index) => (
        <div
          key={index}
          className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
        >
          <img
            src={project.image}
            alt={project.title}
            className="w-full h-48 object-cover"
          />
          <div className="p-6">
            <h3 className="text-xl font-bold mb-2 text-gray-800 dark:text-white">
              {project.title}
            </h3>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              {project.description}
            </p>
            <a
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-4 py-2 bg-indigo-500 text-white rounded-lg shadow-md hover:bg-indigo-600 dark:bg-indigo-400 dark:hover:bg-indigo-500 transition-colors"
            >
              Voir le projet →
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Projects;
