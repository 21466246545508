import React, { useState, useEffect } from "react";

const Hero = () => {
  const [displayedText, setDisplayedText] = useState("");
  const phrases = ["Yoann", "dev web", "dev Discord"];
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [letterIndex, setLetterIndex] = useState(0);

  useEffect(() => {
    const currentPhrase = phrases[phraseIndex];
    const typingSpeed = 150;
    const delayBetweenPhrases = 2000;

    if (letterIndex < currentPhrase.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + currentPhrase[letterIndex]);
        setLetterIndex(letterIndex + 1);
      }, typingSpeed);
      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        setDisplayedText("");
        setLetterIndex(0);
        setPhraseIndex((prev) => (prev + 1) % phrases.length);
      }, delayBetweenPhrases);
      return () => clearTimeout(timeout);
    }
  }, [letterIndex, phraseIndex]);

  return (
    <section className="relative bg-gradient-to-r from-indigo-500 via-purple-600 to-blue-500 text-white min-h-screen flex flex-col justify-center items-center dark:bg-gradient-to-r dark:from-gray-800 dark:via-gray-900 dark:to-gray-800 overflow-hidden">
      {/* Arrière-plan décoratif animé */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-[400px] h-[400px] bg-gradient-to-r from-yellow-300 to-pink-500 rounded-full blur-[120px] opacity-30 animate-bounce-slow" />
        <div className="absolute bottom-10 right-10 w-[300px] h-[300px] bg-gradient-to-r from-purple-600 to-blue-400 rounded-full blur-[100px] opacity-20 animate-pulse-slow" />
        <div className="absolute top-1/3 left-1/4 w-[500px] h-[500px] bg-gradient-to-br from-blue-400 to-green-400 rounded-full blur-[150px] opacity-10 animate-rotate" />
      </div>

      {/* Contenu principal */}
      <div className="container mx-auto text-center px-6 relative z-10">
        <h1 className="text-5xl md:text-6xl font-extrabold mb-6 animate-slide-up tracking-tight">
          Salut, je suis{" "}
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 via-orange-400 to-red-500">
            {displayedText}
          </span>
          <span className="blinking-cursor text-yellow-300">|</span>
        </h1>
        <p className="text-lg md:text-xl max-w-3xl mx-auto mb-8 text-gray-200 dark:text-gray-400 animate-slide-up">
          Passionné par le développement web et la création de{" "}
          <span className="text-yellow-300 dark:text-yellow-400 font-semibold">
            bots Discord
          </span>
          . Je transforme vos idées en projets numériques concrets.
        </p>

        {/* Boutons d'appel à l'action */}
        <div className="flex flex-wrap gap-4 justify-center animate-slide-up">
          <a
            href="/projects"
            className="bg-yellow-300 text-gray-800 py-3 px-8 rounded-lg text-lg font-semibold shadow-lg hover:shadow-yellow-400 transition-transform transform hover:scale-110 dark:bg-yellow-400 dark:text-gray-900 dark:hover:bg-yellow-500"
          >
            Voir mes projets
          </a>
          <a
            href="/contact"
            className="bg-transparent border border-white py-3 px-8 rounded-lg text-lg font-semibold hover:bg-white hover:text-gray-800 transition-transform transform hover:scale-110 dark:hover:bg-gray-700"
          >
            Me contacter
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
